<template>
  <v-container>
    <!-- <h1 class="mt-5 mb-5 white-text text-center" color="white">Services</h1> -->
    <!-- <h1 class="mt-5 mb-5 white-text text-center" color="white">
      <v-btn class="kiosk-book-btn ma-2 white--text">Book Now</v-btn>
    </h1> -->
    <!-- <v-row class="d-flex justify-center">
      <v-col md="3" v-for="service in services" :key="service.id">
        <v-card
          color="white"
          @click="gotoBookingForm(service), (facility = {})"
        >
          <div class="facility-icons pt-10">
            <view-image :image="service.image_path" class="box-image" :height="140"></view-image>
          </div>

          <h3 class="text-center pt-5 pb-10">{{ service.service_name }}</h3>
        </v-card>
      </v-col>
    </v-row> -->
</v-container>
</template>
<script>
import constantValue from "@/utils/constants";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      refreshComponent: 0,
      categories: [],
        categoriesList: [
        { name: "All", id: null },
        { name: "Open Product", id: -1 },
      ],
      constant: constantValue,
      venueServiceId: null,
      isCheckFacilityAvailablity: false,
      isTimeSlotAvailable: false,
      valid: false,
      services: [],
      facility: {},
      perCapacity: 0,
      timeIncrement: 0,
      minBookingTime: 0,
      endTime: [],
      startTime: [],
      facility_list: [],
    };
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  mounted() {
    this.showLoader("Loading");
    this.$store.dispatch("loadKioskVenueServices").then(() => {
      this.$nextTick(() => {
        if (this.$store.getters.getKioskServices.length) {
          // this.initializeScheduleForVenueService();
        }
        this.hideLoader();
      });
    });
    
    // this.getServices();
  },
  methods: {
    async initializeFacilityServices() {
      if (this.gameFormations().length == 0) {
        this.showLoader("Loading");
        await this.$store
          .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200) {
              if (response.data && response.data.data) {
                let data_facility = response.data.data.facilities;
                this.facility_list = data_facility;
                this.facility.date = moment(new Date()).format("Y-M-D");
              }
            }
            this.$nextTick(() => {
              this.hideLoader();
              this.$forceUpdate;
            });
          });
      }
      this.isCheckFacilityAvailablity = true;
    },
    gameFormations() {
      return this.$store.getters.getGameFormationsByVenueServiceId(
        this.venueServiceId
      );
    },
    surfaces() {
      return this.$store.getters.getFacilitySurfaceByVenueServiceId(
        this.venueServiceId
      );
    },
    getServices() {
      this.$http
        .get("venues/kiosk")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.services = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotoBookingForm(service) {
      console.log('booking form', service);
      this.$router.push({
        name: "BookingForm",
        params: {
          data: btoa(JSON.stringify(service)),
        },
      });
    },
    getStartTime() {
      if (
        moment(this.facility.date).format("Y-M-D") ==
        moment(new Date()).format("Y-M-D")
      ) {
        this.startTime = this.constant.TIMINGS.slice(
          new Date().getHours() + 1,
          24
        );
      } else {
        this.startTime = this.constant.TIMINGS.slice(0, 24);
      }
    },
    getAvailablity() {
      this.endTime = [];
      if (!this.facility.start_time) {
        return;
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.isTimeSlotAvailable = false;
      let url = `?venue_service_id=${this.venueServiceId}&date=${this.facility.date}&start_time=${this.facility.start_time}&increment=${this.timeIncrement}&min_booking_time=${this.minBookingTime}&per_capacity=${this.perCapacity}`;

      if (
        this.facility.facility_game_formation_id &&
        this.facility.facility_game_formation_id != null
      ) {
        url += `&game_formation_id=${this.facility.facility_game_formation_id}`;
      }
      if (
        this.facility.facility_surface_id &&
        this.facility.facility_surface_id != null
      ) {
        url += `&facility_surface_id=${this.facility.facility_surface_id}`;
      }
      if (
        this.facility.id &&
        this.facility.id != null
      ) {
        url += `&facility_id=${this.facility.id}`;
      }
      this.showLoader("Checking Availablity.....");
      this.$http
        .get("venues/kiosk/facility-availability" + url)
        .then((response) => {
          if (response.data.status == true) {
            this.facility.venue_service_id = this.venueServiceId;
            this.facility.facility_id = response.data.data.id;
            this.facility.facility_rentals =
              response.data.data.facility_rentals;
            this.endTime = response.data.data.end_times;

            this.hideLoader();
          } else {
            // this.showError("No Facility Available for this time");
            this.isTimeSlotAvailable = true;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    checkAvailablity() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (this.facility.facility_rentals.length > 0) {
        this.facility.facility_rentals[0].quantity = this.endTime.find(
          (y) => y.time == this.facility.end_time
        ).quantity;
      }

      this.$router.push({
        name: "BookingForm",
        params: {
          data: btoa(JSON.stringify(this.facility)),
        },
      });
    },
  },
};
</script>
<style>
.box-image > div.v-image__image{
  background-size: contain;
}

</style>